import { API, logError } from "./index";

const getMyModules = async () => {
  try {
    return await API().get(`/my-module`);
  } catch (error) {
    return logError(error);
  }
} 

const getRecentlyVisitedModules = async () => {
  try {
    return await API().get(`/recently-modules-member`);
  } catch (error) {
    return logError(error);
  }
};

const getCMEs = async (filters) => {
  
  let URL = `/cme?page=${filters.page}`;

  if (filters.partner) URL = `${URL}&partner_id=${filters.partner}`;
  if (filters.speciality) URL = `${URL}&specialty=${filters.speciality}`;
  if (filters.date) URL = `${URL}&date=${filters.date}`;

  try {
    return await API().get(URL);
  } catch (error) {
    return logError(error);
  }
};

const moduleVisited = async (moduleID) => {

  try {
    return await API().post(`/user-visit?module_id=${moduleID}`);
  } catch (error) {
    return logError(error);
  }
};

const moduleTask = async (page) => {

  try {
    return await API().get(`/module-task?page=${page}`);
  } catch (error) {
    return logError(error);
  }
};

const moduleCount = async () => {

  try {
    return await API().get('/user-module-count');
  } catch (error) {
    return logError(error);
  }
};

const recentlyVisitedCount = async () => {

  try {
    return await API().get('/recently-visited-member-count');
  } catch (error) {
    return logError(error);
  }
};

export {
  getMyModules,
  getRecentlyVisitedModules,
  getCMEs,
  moduleVisited,
  moduleTask,
  moduleCount,
  recentlyVisitedCount
};